<template>
  <section>
    <div>
      <div class="w-full lg:flex">
        <div class="lg:w-4/12">
          <AutoComplete v-model="contratosStore._articulos.lista_precio_id" size="small" optionLabel="Nombre" dropdown :force-selection="true" inputClass="w-full text-sm" panelClass="text-sm" class="w-9/12 h-10" :suggestions="listaPrecioStore._listas_precio_buscadas" @complete="buscarListas($event)" />
          <div class="w-full">            <span class="text-xs italic  text-gray-600 w-full">
              Si no encuentras el listado de precios adecuado
              <router-link target="_blank" :to="{name: 'pharmasan.ventas.lista-precio', target: '_blank', query: { crear: true }}"
                class="text-blue-600 font-bold italic"
              >
                +Crea uno
              </router-link>
            </span>
          </div>
        </div>
        <div class="lg:flex lg:w-8/12 justify-end gap-2">
          <div class="lg:w-4/12 h-11" v-if="documento">
            <div class="bg-blue-300 flex justify-between rounded-md h-full p-2 text-blue-600 font-bold text-sm border border-r-2">
              <div class="flex gap-2">
                <p>{{ documento ?  documento.name : 'articulos.xlsx' }}</p>
              </div>
              <button @click="eliminarArchivo">
                <i class="pi pi-times text-sm"></i>
              </button>
            </div>
          </div>
          <FileUpload
            v-else
            mode="basic"
            class="h-10 w-full bg-white text-blue-600"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :maxFileSize="1000000"
            chooseLabel="Actualizar articulos masivo"
            @change="subirArticulos()"
            ref="file"
          ></FileUpload>
          <Button v-if="documento" class="h-10 lg:w-4/12" severity="success" label="Guardar" @click="actualizarArticulosMasivo" icon="pi pi-save" />
          <Button @click="displayModalInstruccionesPlantilla = true" class="h-10 lg:w-4/12" label="Descargar plantilla articulos" icon="pi pi-download" severity="secondary" />
          <Button @click="displayModalCrearArticulo = true" label="Crear articulo" v-if="!documento" class="h-10 lg:w-4/12" icon="pi pi-plus" />
        </div>
      </div>
      <div class="w-full mt-4 py-2">
        <div class="w-full">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filtros.ItemCode.value" @input="getArticulos" placeholder="Buscar articulo" />
          </span>
        </div>
        <DataTable
          :value="contratosStore._paginado_articulos.rows"
          class="text-xs p-datatable-sm mt-2"
          showGridlines
        >
          <Column field="ItemCode" header="Medicamentos"></Column>
          <Column field="ItemName" header="Nombre">
            <template #body="slotProps">
              <div class="flex gap-2">
                <p>
                  <span class="font-medium">Medicamento</span>
                  {{ slotProps.data.ItemName }}
                </p>
              </div>
              <div class="flex gap-2">
                <p>
                  <span class="font-medium">P. activo</span>
                  {{ slotProps.data.U_PHR_PrincActivo }}
                </p>
              </div>
            </template>
          </Column>
          <!-- <Column field="UserText" header="Descripción"></Column> -->
          <Column field="Agrupacion" header="Agrupacion"></Column>
          <Column field="Precio" :style="{ 'width': '5rem' }" header="Precio">
            <template #body="slotProps">
              <div class="flex gap-2">
                <InputNumber inputClass="w-24" v-if="slotProps.data.editar" v-model="slotProps.data.precio_editar" inputId="withoutgrouping" :useGrouping="false" />
                <p v-else class="text-center">{{ $h.formatCurrency(slotProps.data.Precio) }}</p>
                <button @click="slotProps.data.editar = true" v-if="!slotProps.data.editar"><i class="pi pi-pencil text-xs text-blue-600"></i></button>
                <div class="flex gap-2" v-else>
                  <Button icon="pi pi-save" @click="validarListasContratos(slotProps.data)" rounded outlined aria-label="Guardar" />
                  <Button @click="slotProps.data.editar = false" icon="pi pi-times" severity="danger" rounded outlined aria-label="Cancelar" />
                </div>
              </div>
            </template>
          </Column>
          <Column field="U_PHR_PrincActivo" header="Estado">
            <template #body="slotProps">
              <InputSwitch @change="cambiarEstadoArticulo(slotProps.data)" v-model="slotProps.data.Status" />
            </template>
          </Column>
        </DataTable>
        <Paginator :rows="10" @page="onPage($event)" :totalRecords="contratosStore._paginado_articulos.count" :rowsPerPageOptions="[10, 20, 30, 100, 500]"></Paginator>
      </div>
    </div>
    <Dialog modal v-model:visible="displayModalCrearArticulo" header="Crear articulo" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="w-full">
        <label class="w-full text-xs text-gray-600 italic" for="itemCode">Codigo del articulo</label>
        <InputText class="w-full mb-2" id="itemCode" type="text" v-model="dataCrearArticulo.ItemCode" />
        <label class="w-full text-xs text-gray-600 italic" for="tipo">Tipo</label>
        <Dropdown id="tipo" v-model="dataCrearArticulo.tipo" :options="tiposArticulo" class="w-full" />
        <div class="mt-4 flex justify-end gap-2">
          <Button @click="cerrarModal" severity="secondary" label="Cancelar" />
          <Button @click="crearArticulo" label="Guardar" />
        </div>
      </div>
    </Dialog>
    <Dialog v-model:visible="displayModalInstruccionesPlantilla" modal header="Importar articulos masivo" :style="{ width: '35rem' }">
      <div class="text-sm mb-4">
        <p class="font-medium">Pasos para ingresar medicamentos:</p>
        <DataTable :value="pasosDescargaPlantilla" size="small" class="text-xs" showGridlines>
          <Column field="campo" header="Campo"></Column>
          <Column field="condicion" header="Condición"></Column>
          <Column field="descripcion" header="Descripción"></Column>
        </DataTable>
        <span class="text-xs font-bold italic">!Importante: Los medicamentos ingresados en la plantilla deben pertenecer a la lista de precios, de lo contrario no se asociaran al contrato</span>
      </div>
      <div class="flex justify-content-end gap-2 text-right">
        <Button type="button" label="Cancelar" severity="secondary" @click="displayModalInstruccionesPlantilla = false"></Button>
        <Button @click="contratosStore.descargarPlantillaMasivoArticulos(), displayModalInstruccionesPlantilla = false" class="h-10" label="Descargar plantilla articulos" icon="pi pi-download" />
      </div>
    </Dialog>
  </section>
</template>
<script setup>
  import { useContratosStore } from '../../../stores/contratos.store'
  import { useListaPrecioStore } from '../../../stores/lista-precio.store'
  import { ref, onMounted, computed } from 'vue'
  import { FilterMatchMode } from 'primevue/api'
  import { useToast } from 'primevue/usetoast'
  import { useRoute } from 'vue-router'
  import ServiceContrato from '../../../services/contrato-precio.service'
  import { helper } from '@/utils/helper'
  import Swal from 'sweetalert2'
  const toast = useToast()
  const route = useRoute()
  const offset = ref(0)
  const limit = ref(10)
  const documento = ref()
  const displayModalCrearArticulo = ref(false)
  const displayModalInstruccionesPlantilla = ref(false)
  const params = computed(() => {
    return {
      offset: offset.value,
      limit: limit.value
    }
  })
  const dataCrearArticulo = ref({
    ItemCode: '',
    tipo: '',
    ListPrice: 0
  })
  const pasosDescargaPlantilla = ref([
    {
      campo: 'ItemCode',
      condicion: 'Obligatorio',
      descripcion: 'Se digita el código del medicamento relacionado'
    },
    {
      campo: 'TipoArt',
      condicion: 'Opcional',
      descripcion: 'Se digita para CRONICO -> 0 ó para AGUDO -> 1'
    }
  ])
  const tiposArticulo = ref(['CRONICO', 'AGUDO'])
  const contratosStore = useContratosStore()
  const listaPrecioStore = useListaPrecioStore()
  const _serviceContrato = new ServiceContrato()
  const file = ref()
  const filtros = ref({
      ItemCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  const subirArticulos = () => {
    const archivo = file.value.files[0]
    documento.value = archivo
    if (archivo) {
      const reader = new FileReader()
      reader.onload = async (event) => {
        contratosStore.medicamentos_ingresados = { base64: await helper.base64String(archivo), ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL }
        const validArt = await _serviceContrato.validArticulos({ base64: await helper.base64String(archivo) }, contratosStore._articulos.lista_precio_id.ListPriceCL)
        if (validArt.data) {
          Swal.fire({
            icon: 'warning',
            title: 'Validacion Articulos',
            text: validArt.data
          })
        }
      }
      reader.readAsArrayBuffer(archivo)
    }
  }
  const buscarListas = ({ query }) => {
    listaPrecioStore.buscarListaPrecio(query || 'a')
  }
  const eliminarArchivo = () => {
    contratosStore.medicamentos_ingresados = []
    contratosStore.articulos.articulos = []
    documento.value = null
  }
  const getArticulos = () => {
    const busqueda = {}
    for (const [key, value] of Object.entries(filtros.value)) {
      if (value.value) {
        busqueda[key] = value.value
      }
    }
    const parametros = {
      ...params.value,
      ...busqueda
    }
    contratosStore.paginarArticulos(route.params.id, parametros)
  }
  const onPage = ({ first, rows }) => {
    offset.value = first
    limit.value = rows
    getArticulos()
  }
  const crearArticulo = () => {
    if (!contratosStore._articulos.lista_precio_id?.ListPriceCL) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes seleccionar una lista de precio para coontinuar', life: 5000 })
    const payload = {
      ItemCode: dataCrearArticulo.value.ItemCode.trim(),
      ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL,
      tipo: dataCrearArticulo.value.tipo
    }
    contratosStore.crearArticulosContrato(route.params.id, { ListPrice: contratosStore._articulos.lista_precio_id.ListPriceCL, data: [payload] }).then((data) => {
      if (data) {
        Swal.fire({
          title: 'Guardado',
          text: 'Articulo agregado',
          icon: 'success'
        }).then(() => {
          getArticulos()
        })
        cerrarModal()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un error al ingresar el articulo'
        })
      }
    })
  }
  const cerrarModal = () => {
    displayModalCrearArticulo.value = false
    dataCrearArticulo.value = {
      ItemCode: '',
      tipo: ''
    }
  }
  const cambiarEstadoArticulo = (articulo) => {
    contratosStore.cambiarEstadoArticuloContrato(route.params.id, articulo.Id, { Status: articulo.Status }).then(() => {
      toast.add({ severity: 'info', summary: 'Estado cambiado con éxito', detail: `Se cambo el estado del medicamento ${articulo.ItemCode} a ${articulo.Status ? 'Activo' : 'Inactivo'}`, life: 5000 })
    })
  }
  const actualizarArticulosMasivo = () => {
    contratosStore.crearArticulosContrato(route.params.id, contratosStore.medicamentos_ingresados).then((data) => {
      if (data) {
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: data
        }).then(() => {
          contratosStore.medicamentos_ingresados = {}
          documento.value = null
          getArticulos()
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocurrio un erro al cargar los articulos, intenta de nuevo mas tarde'
        })
      }
    })
  }
  const validarListasContratos = (linea) => {
    contratosStore.contratosPorListaPrecio(contratosStore._articulos.lista_precio_id.ListPriceCL).then((data) => {
      if (data.length > 1) {
        Swal.fire({
          title: 'Advertencia',
          html: `
            La lista de precio se encuentra asociada con los siguientes contratos:
            <table class="border">
              <thead>
                <tr class="border">
                  <th>Contrato</th>
                </tr>
              </thead>
              <tbody>
                ${data.map(a => {
                  return `<tr class="border text-left"><td class="border text-left"> ${a.NumCto + '-' + a.Descript}</td></tr>`
                })}}
              </tbody>
            </table>
            ¿esta seguro(a) de continuar?
            `,
          showCancelButton: true,
          confirmButtonText: 'Actualizar',
          cancelButtonText: 'Cancelar',
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            actualizarPrecio(linea)
          } else if (result.isDenied) {
            linea.editar = false
            linea.precio_editar = linea.Precio
          }
        })
      } else {
        actualizarPrecio(linea)
      }
    })
  }
  const actualizarPrecio = (linea) => {
    listaPrecioStore.actualizarPrecioUnitario(linea.PrecioId, { Precio: linea.precio_editar }).then(() => {
      linea.Precio = linea.precio_editar
      linea.editar = false
      toast.add({ severity: 'success', summary: 'Guardado', detail: `Precio del medicamento ${linea.ItemCode} actualizado con éxito`, life: 8000 })
    })
  }
  onMounted(() => {
    listaPrecioStore.listarListasPrecio()
    getArticulos()
  })
</script>
